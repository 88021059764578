<template>
  <div id="app" style="height: 100vh">
    <router-view />
  </div>
</template>

<script>
import { queryPlatformPath } from "@/api/home";
export default {
  data() {
    return {};
  },
  async created() {
    await queryPlatformPath().then((res) => {
      this.$store.dispatch("setProjectInfoServe", res);
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/css/global.css"; // 引入全局CSS文件
html,
body {
  padding: 0 !important;
  margin: 0 !important;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userUrl: "",//用户登录
        expertUrl: "",//专家登录
        websiteUrl: "https://www.bhyijiantong.cn",//保函办理
        ggzUrl: "https://hebcaonline.hebca.com/Hebca/main_ggzy_2.jsp?onlineType=4&projectType=bfhztb",//CA办理
    },
    mutations: {
        setProjectInfoServe(state, value) {
            state.userUrl = value.homePagePath + '/business/login';
            state.expertUrl = value.evaluationPath + '/reviewa/login';
        },
    },
    actions: {
        setProjectInfoServe({ commit }, value) {
            commit('setProjectInfoServe', value)
        },
    }
});
import axios from 'axios'
import {API_BASE_URL} from '@/config/setting'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// const API_BASE_URL = 'http://192.168.123:8002/'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/api',
  // 超时
  timeout: 10000
})

export default service

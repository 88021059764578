import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
      path:'/',
      redirect:'/home'
  },
  {
      path: '/home',
      name:'home',
      component:()=>import('@/views/home/index.vue'),
      meta: {
        title:'象新科技电子招投标系统'
      }
  },
    {
        path: '/zbinfo',
        name:'zbinfo',
        component:()=>import('@/views/zbInfo/index.vue'),
        meta: {
            isShow:true,
            title:'招标信息_象新科技电子招投标系统'
        }
    },
    {
        path: '/winning',
        name:'winning',
        component:()=>import('@/views/winning/index.vue'),
        meta: {
            isShow: false,
            title:'公告'
        }
    },
    {
        path: '/tools',
        name:'tools',
        component:()=>import('@/views/tools/index.vue'),
        meta: {
            isShow: true,
            title:'常用工具_象新科技电子招投标系统'
        }
    },
    {
        path: '/platform',
        name:'platform',
        component:()=>import('@/views/platform/index.vue'),
        meta: {
            isShow: true,
            title:'资讯中心_象新科技电子招投标系统'
        }
    },
    {
        path: '/law',
        name:'law',
        component:()=>import('@/views/law/index.vue'),
        meta: {
            isShow: true,
            title:'政策法规_象新科技电子招投标系统'
        }
    },
    {
        path: '/help',
        name:'law',
        component:()=>import('@/views/help/index.vue'),
        meta: {
            isShow: true,
            title:'帮助中心_象新科技电子招投标系统'
        }
    },
    {
        path: '/propagate',
        name:'propagate',
        component:()=>import('@/views/Propagate/index.vue'),
        meta: {
            isShow: true,
            title:'公司宣传_象新科技电子招投标系统'
        }
    },
    {
        path: '/lawContent',
        name:'law',
        component:()=>import('@/views/lawContent/index.vue'),
        meta: {
            isShow: false,
            title:'公告'
        }
    },
    {
        path: '/platformContent',
        name:'law',
        component:()=>import('@/views/platformContent/index.vue'),
        meta: {
            isShow: false,
            title:'公告'
        }
    },
    {
        path: '/zbInfoPhone',
        name:'zbInfoPhone',
        component:()=>import('@/views/zbInfoPhone/index.vue'),
        meta: {
            isShow: true,
            tap:'three',
            title:'招标信息_象新科技电子招投标系统'
        }
    },
    
    {
        path: '/newsPhone',
        name:'newsPhone',
        component:()=>import('@/views/newsPhone/index.vue'),
        meta: {
            isShow: true,
            tap:'two',
            title:'新闻公告_象新科技电子招投标系统'
        }
    },
    {
        path: '/contentPhone',
        name:'contentPhone',
        component:()=>import('@/views/lawContentPhone/index.vue'),
        meta: {
            isShow: false,
            title:'新闻内容'
        }
    },
    {
        path: '/winningPhone',
        name:'winningPhone',
        component:()=>import('@/views/winningPhone/index.vue'),
        meta: {
            isShow: false,
            title:'公告'
        }
    },
]

//全局路由守卫----初始化的时候被调用，每次路由切换之前被调用


const router = new VueRouter({
  mode: "hash",
  // base: "http://192.168.0.166:20000",
  routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})
// beforeEach是router的钩子函数，在进入路由前执行
router.beforeEach((to, from, next) => {
   
        document.title = to.meta.title
    
    next()
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'


// import MetaInfo from 'vue-meta-info'

Vue.prototype.$http = axios;
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueDirectiveImagePreviewer,{
  zIndex: '8' 
})  //图片放大插件

new Vue({
  router,
  store,
  data: function(){
      return {
          NAVACTIVE: 0,
      }
  },
  render: h => h(App),
  // mounted() {
  // 	document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')